import React from 'react'

import { Typography } from 'components/UI'

export const ClientAgreement = () => {

  return (
    <div>
      <Typography text="Клиентское соглашение"/>
      <p>
        Настоящие Соглашение регулирует порядок использования Сервиса и/или Платформы (как
        определено ниже). Используя Сервис и/или Платформу, Стороны безоговорочно принимают условия
        настоящего Соглашения
      </p>

      <p>
        <b>
          1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ
        </b>
      </p>

      <p>
        1.1. Если иное не предусмотрено в тексте Соглашения, термины, указанные в настоящем
        Соглашении с заглавной буквы, используются в значениях, указанных в настоящей Статье 1
        Соглашения:
      </p>

      <table>
        <tbody>
        <tr>
          <td><b>Сервис</b></td>
          <td>Сервис Finance & Insurance Hub, размещённый в информационно-телекоммуникационной сети
            «Интернет», используя Платформу, позволяет Клиенту рассчитать стоимость и/или подать
            заявку на заключение договора с Поставщиком услуг;
          </td>
        </tr>
        <tr>
          <td><b>Оператор</b></td>
          <td>Оператором Сервиса и Платформы является ООО «Кредитит» (ИНН 9710013385, ОГРН
            1167746577019);
          </td>
        </tr>
        <tr>
          <td><b>Платформа</b></td>
          <td>программное обеспечение «Программа «е-Кредит» и/или программное обеспечение «Платформа
            еКредит», зарегистрированное в Федеральной службе по интеллектуальной собственности РФ,
            номер регистрации (свидетельства) 2021610753, дата регистрации: 19.01.2021;
          </td>
        </tr>
        <tr>
          <td><b>Соглашение</b></td>
          <td>настоящее Клиентское Соглашение;</td>
        </tr>
        <tr>
          <td><b>Согласие на обработку персональных данных</b></td>
          <td>согласие Клиента на обработку персональных данных для целей и по форме настоящего
            Соглашения;
          </td>
        </tr>
        <tr>
          <td><b>Согласие на запрос в бюро кредитных историй</b></td>
          <td>согласие Клиента на запрос в бюро кредитных историй для целей и по форме настоящего
            Соглашения;
          </td>
        </tr>
        <tr>
          <td><b>Автопроизводитель</b></td>
          <td>юридическое лицо и/или индивидуальный предприниматель, который осуществляет
            деятельность по производству автомобилей для цели их дальнейшей продажи;
          </td>
        </tr>
        <tr>
          <td><b>Дилер</b></td>
          <td>юридическое лицо и/или индивидуальный предприниматель, который осуществляет
            деятельность по продаже автомобилей физическим и/или юридическим лицам;
          </td>
        </tr>
        <tr>
          <td><b>Поставщик услуг</b></td>
          <td>юридическое лицо и/или индивидуальный предприниматель, который Клиенту продаёт товары
            и/или оказывает различные услуги, в том числе, но не ограничиваясь банковские,
            страховые, финансовые, лизинговые и иные;
          </td>
        </tr>
        <tr>
          <td><b>Сайт</b></td>
          <td>веб-сайт, расположенный в информационно-телекоммуникационной сети «Интернет», который
            использует Сервис и/или Платформу, но не управляется и не контролируется Оператором;
          </td>
        </tr>
        <tr>
          <td><b>Клиент</b></td>
          <td>Физическое лицо, заключающее (акцептирующее) настоящее Соглашение для цели
            приобретения у Дилера автомобиля и/или заключения договора с Поставщиком услуг;
          </td>
        </tr>
        <tr>
          <td><b>Анкета</b></td>
          <td>размещенная на Сервисе и/или в Платформе форма анкеты, заполняемая Клиентом в целях
            расчета стоимости и/или отправки заявки на заключение договора с Поставщиком услуг;
          </td>
        </tr>
        <tr>
          <td><b>Электронная подпись (ЭП)</b></td>
          <td>Электронная подпись Клиента, которая представляет собой код из каких-либо символов
            (цифр и/или букв, и/или иных), направляемый Клиенту СМС-сообщением на номер мобильного
            телефона, представленный им при заполнении Анкеты.
          </td>
        </tr>
        </tbody>
      </table>

      <p>
        1.2. Термин, относящейся к какой-либо Стороне и используемый в Соглашении в единственном
        числе, распространяет условие соответствующего пункта на неограниченное количество лиц,
        определяемых соответствующим термином, если прямо не указано иное.
      </p>

      <p>
        <b>
          2. ОБЩИЕ ПОЛОЖЕНИЯ
        </b>
      </p>

      <p>
        2.1. Настоящие Соглашение регулирует отношения, возникающие в процессе использования Сервиса
        и/или Платформы всеми возможными участниками: Оператором и/или Клиентом, и/или Дилером,
        Автопроизводителем, и/или Поставщиком финансовых услуг, и/или Сайтом (далее – совместно
        именуемые «Стороны», а по отдельности – «Сторона»).
      </p>
      <p>
        2.2. Оператор предлагает использовать Сервис и/или Платформу:
        <li>Клиенту - на условиях Соглашения;</li>
        <li>Дилеру, Автопроизводителю, Поставщику услуг, Сайту - на условиях Соглашения и
          заключаемого дополнительного договора.
        </li>
      </p>
      <p>
        2.3. Актуальная версия Соглашения расположена в информационно-телекоммуникационной сети
        «Интернет» на сайте <a
        href="https://www.ecredit.one/user_agreement">https://www.ecredit.one/user_agreement</a>.
        Соглашение имеет превалирующую силу над любым другим договором между Оператором и Стороной,
        если соответствующим договором прямо не предусмотрено иное.
      </p>
      <p>
        2.4. Для Клиента Соглашение вступает в силу с момента начала использования:
      </p>
      <p>
        2.4.1. Сервиса, а именно с момента открытия любой страницы сайта, на котором размещён Сервис
        (его часть);
      </p>
      <p>
        2.4.2. Платформы, а именно с момента передачи своих персональных данных в Платформу путём
        подписания соответствующего согласия.
      </p>
      <p>
        2.5. Для Дилера, Автопроизводителя, Поставщика услуг и Сайта Соглашение вступает в силу с
        момента подписания любого договора с Оператором в письменной форме.
      </p>
      <p>
        2.6. Настоящее Соглашение в соответствии со статьёй 437 Гражданского Кодекса Российской
        Федерации является публичной безотзывной офертой. Полным и безоговорочным акцептом настоящей
        публичной оферты является любое использование Сторонами Сервиса и/или Платформы (его
        функционала).
      </p>
      <p>
        2.7. Настоящее Соглашение действует бессрочно до момента полного отказа Стороны от
        использования Сервиса и/или Платформы, выраженного в письменной форме.
      </p>

      <p>
        <b>
          3. УСЛОВИЯ ИСПОЛЬЗОВАНИЯ ЭЛЕКТРОННОГО ДОКУМЕНТООБОРОТА
        </b>
      </p>

      <p>
        3.1. Клиент в отношениях со Сторонами вправе использовать электронный документооборот на
        условиях настоящего раздела 3 Соглашения.
      </p>
      <p>
        3.2. Клиент использует электронный документооборот при одном из следующих условий:
        <li>наличие у Клиента Лицензиата усиленной ЭП (неквалифицированной или
          квалифицированной);</li>
        <li>наличие у Клиента Лицензиата подтвержденной учетной записи в ЕСИА;</li>
        <li>акцепт Клиентом Лицензиата соглашения с Лицензиаром об использовании простой ЭП,
          выпускаемой Оператором.
        </li>
      </p>
      <p>
        3.3. Клиент, желающий с помощью Сервиса и/или Платформы заключить договор с Поставщиком
        услуг, обязуется передать Поставщику услуг Согласие на обработку персональных данных,
        Согласие на запрос в Бюро кредитных историй и Анкету. Стороны договорились, что такие
        документы передаются в электронном виде с использованием ЭП.
      </p>
      <p>
        3.4. Простая ЭП, выпускаема Оператором, представляет собой код из каких-либо символов (цифр
        и/или букв, и/или иных символов), направляемый Клиенту СМС-сообщением на номер мобильного
        телефона, предварительно предоставленный Клиентом.
      </p>
      <p>
        3.5. Клиент для рассмотрения Поставщиком услуг Анкеты для заключения договора обязан
        подписать с помощью ЭП (путём ввода кода в соответствующее поле) Согласие на обработку
        персональных данных, Согласие на запрос в Бюро кредитных историй и Анкету.
      </p>
      <p>
        3.6. Если Клиент не совершит действие, указанное в пункте 3.5. Соглашения, в течение 60
        (шестидесяти) минут с момента получения СМС-сообщения согласно п. 3.4. Соглашения, простая
        ЭП, выпускаемая Оператором, становится недействительной.
      </p>
      <p>
        3.7. Клиент соглашается и договаривается с другими Сторонами, что Согласие на обработку
        персональных данных, Согласие на запрос в Бюро кредитных историй и Анкета, а также
        какие-либо иные документы в электронном виде, подписанные ЭП Клиента, признаются
        равносильными документам на бумажном носителе, подписанным собственноручной подписью Клиента
        в отношениях с другой Стороной.
      </p>
      <p>
        3.8. Одной ЭП могут быть подписаны несколько связанных между собой документов, в том числе,
        и договор с Поставщиком услуг. Клиент подписанием любого документа (соглашения, договора),
        перечисленного в настоящем Соглашении, с помощью ЭП заверяет, что действует добросовестно и
        даёт основания другим Сторонам полагаться на действительность сделки.
      </p>
      <p>
        3.9. В случае направления предложения со стороны Поставщика услуг в адрес Клиента о
        заключении договора, такой договор может быть заключён с помощью указанной ЭП, если это
        прямо предусмотрено Поставщиком услуг и на указанных им условиях.
      </p>
      <p>
        3.10. В случае использования Клиентом простой ЭП, выпускаемой Оператором, Оператор обязуется
        в автоматическом режиме проверять совпадение пары кода из СМС-сообщения и номера мобильного
        телефона Клиента, при подписании Клиентом с её помощью любых документов с использованием
        Сервиса и/или Платформы и фиксировать результат такой проверки в своей внутренней системе.
        По запросу Поставщика услуг Оператор предоставляет результат такой проверки.
      </p>
      <p>
        3.11. Датой подписания любого документа с помощью ЭП является дата её проставления (ввода)
        Клиентом.
      </p>
      <p>
        3.12. Стороны согласны, что какие-либо документы, подписанные Клиентом ЭП, не могут быть
        признаны не имеющими юридической силы на основании, что такая ЭП создана с использованием
        средств ЭП для автоматического создания и/или автоматической проверки ЭП.
      </p>
      <p>
        3.13. Стороны обязаны соблюдать конфиденциальность ЭП и несут ответственность за последствия
        необеспечения такой конфиденциальности.
      </p>
      <p>
        3.14. Если у какой-либо Стороны, использующей простую ЭП, выпущенной Оператором, появились
        подозрения в её компрометации, или ей стало достоверно известно о её компрометации, она
        обязуется незамедлительно сообщить об этом остальным Сторонами. Под компрометацией простой
        ЭП, выпущенной Оператором, понимается её потеря, возникновение подозрений об её утечке или
        искажении в системе, утрата Клиентом контроля за ограничением доступа к ней, другие виды её
        разглашения.
      </p>
      <p>
        3.15. Оператор в случае получения информации о компрометации и/или подозрении в
        компрометации простой ЭП в порядке пункта 3.14. Соглашения обязуется незамедлительно её
        деактивировать и выпустить новую ЭП. На вновь выпущенную Оператором простую ЭП
        распространяются все положения настоящего Соглашения, которые относятся к ЭП.
      </p>
      <p>
        3.16. Все документы, подписанные ЭП до момента её деактивации, считаются подписанными
        Клиентом и продолжают своё действие.
      </p>
      <p>
        3.17. Если у какой-либо Стороны появились подозрения в компрометации иной ЭП, используемой
        Клиентом, Стороны дополнительно обговаривают порядок своих действий.
      </p>

      <p>
        <b>
          4. СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ И НА ЗАПРОС В БЮРО КРЕДИТНЫХ ИСТОРИЙ
        </b>
      </p>
      <p>
        4.1. Оператор на основании подписанного Клиентом Согласия на обработку персональных данных
        имеет право (в том числе, с использованием Платформы) обрабатывать персональные данные,
        предоставленные Клиентом в Анкете, расположенной на Сервисе и/или Платформе, любыми
        способами предусмотренными действующим законодательством РФ, в том числе правом на передачу
        персональных данных по информационным каналам сети Интернет Дилеру, Автопроизводителю,
        Поставщику услуг, Сайту, а также на совершение любых действий с указанными персональными
        данными с использованием средств автоматизации или без, в том числе на сбор, запись,
        систематизацию, накопление, хранение, уточнение, извлечение, использование, обезличивание,
        блокирование, передачу, удаление и уничтожение.
      </p>
      <p>
        4.2. Использую Сервис и/или Платформу, Клиент выражает свое согласие Оператору на
        автоматизированную обработку персональных данных (файлы cookie, сведения о действиях Клиента
        на сайте, сведения об оборудовании Клиента, дата и время сессии), в том числе с
        использованием различных метрических и аналитических программ, с совершением действий: сбор,
        запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение,
        использование, обезличивание, блокирование, удаление, уничтожение, передача (предоставление,
        доступ) другим Сторонам. Обработка персональных данных осуществляется в целях улучшения
        работы Сервиса и/или Платформы, совершенствования товаров и услуг Сторон, определения
        предпочтений Клиента, предоставления целевой информации по товарам и услугам Сторон и их
        партнёров.
      </p>
      <p>
        4.3. Оператор на основании подписанного Клиентом согласия на запрос в Бюро кредитных историй
        имеет право запрашивать данные из Бюро кредитных историй в объёме и в течение срока в
        соответствии с полученным согласием и действующим законодательством РФ.
      </p>
      <p>
        4.4. Целями предоставления Оператору персональных данных и права на запрос в Бюро кредитных
        историй является передача персональных данных Клиента и данного права в отношении Клиента
        Автопроизводителю, Дилеру, Поставщику услуг, Сайту и возможность заключения с Поставщиком
        услуг каких-либо договоров.
      </p>
      <p>
        4.5. Согласие на обработку персональных данных, предоставленное Клиентом Оператору,
        действует с момента его подписания и до даты, наступающей через 5 (пять) лет с даты
        прекращения обязательств Клиента по заключенным с Оператором договорам, и может быть
        отозвано путем предоставления Оператору заявления Клиента в простой письменной форме.
      </p>
      <p>
        4.6. Согласие на запрос в Бюро кредитных историй, предоставленное Клиентом Оператору,
        действует в течение шести месяцев со дня его подписания, и может быть отозвано путем
        предоставления Оператору заявления Клиента в простой письменной форме.
      </p>
      <p>
        4.7. Использование (обработка) персональных данных Автопроизводителем, Дилером, Поставщиком
        услуг, Сайтом ведется в соответствии с Политикой (правилами) обработки персональных
        соответствующего Автопроизводителя, Дилера, Поставщика услуг и Сайта.
      </p>
      <p>
        4.8. Акцептуя данное Соглашение, Клиент подтверждает, что ознакомлен и с политикой
        (правилами) обработки персональных данных Автопроизводителя, Дилера, Поставщика услуг и
        Сайта.
      </p>
      <p>
        4.9. Оператор вправе запрашивать данные о Клиенте у Бюро кредитных историй для установления
        факта заключения или не заключения им договора с другой Стороной.
      </p>

      <p>
        <b>
          5. УСЛОВИЯ ИСПОЛЬЗОВАНИЯ СЕРВИСА И/ИЛИ ПЛАТФОРМЫ
        </b>
      </p>
      <p>
        5.1. Каждая из Сторон самостоятельно несет ответственность за свои действия, связанные с использованием Сервиса и/или Платформы.
      </p>
      <p>
        5.2. Условия размещения Сервиса и/или Платформы и доступа к нему у Автопроизводителя, Дилера, Поставщика услуг, Сайта регулируются отдельными договорами, заключаемыми с Оператором.
      </p>
      <p>
        5.3. Оператор через Сервис и/или Платформу позволяет осуществлять размещение Клиентом заявок на заключение договоров с Поставщиком услуг, получать одобрение или отказ в заключении таких договоров и отслеживать их исполнение. Оператор не является стороной подобных договоров и не несёт ответственность за их исполнение (в том числе, частичное) или неисполнение.
      </p>
      <p>
        5.4. Размещение заявок на заключение договора с Поставщиком услуг производится с согласия и на основании данных, добровольно предоставляемых Клиентом. Согласие на передачу данных подтверждается подписанием Соглашения посредством ЭП.
      </p>
      <p>
        5.5. Для размещения заявок на заключение договора с Поставщиком услуг Клиент обязуется дополнительно оформить согласие на обработку его персональных данных и согласие на получение кредитных отчётов из бюро кредитных историй (по формам, приложенным к Соглашению). Указанные согласия подписывается Клиентом посредством ЭП.
      </p>
      <p>
        5.6. После размещения заявок на заключение договора с Поставщиком услуг Клиент вступает в правоотношения с соответствующим Поставщиком услуг. Коммерческие условия, порядок исполнения и иные условия договора между Клиентом и Поставщиком услуг регулируются без участия Оператора.
      </p>
      <p>
        5.7. Оператор осуществляет мониторинг размещения заявок на заключение договора с Поставщиком услуг, а также их исполнения в автоматическом режиме для сбора статистических данных. Оператор вправе по своему усмотрению распоряжаться данными статистическими данными.
      </p>
      <p>
        5.8. Стороны обязуются использовать Сервис и/или Платформу исключительно в целях, не запрещённых действующим законодательством Российской Федерации.
      </p>
      <p>
        5.9. Стороны обязуется не воспроизводить, не повторять, не копировать, не декомпилировать Сервис и/или Платформу.
      </p>
      <p>
        5.10. Автопроизводитель, Дилер, Поставщик услуг, Сайт вправе использовать Сервис и/или Платформу для собственных коммерческих целей. При этом все возникающие налоги и сборы при таком использовании оплачиваются соответствующей Стороной в полном объёме.
      </p>
      <p>
        5.11. Клиент, Автопроизводитель, Дилер, Поставщик услуг, Сайт самостоятельно несет ответственность перед каждой из Сторон, а также третьими лицами за свои действия, связанные с использованием Сервиса и/или Платформы, в том числе, если такие действия приведут к нарушению прав и законных интересов третьих лиц, а также за соблюдение законодательства при использовании Сервиса и/или Платформы.
      </p>
      <p>
        5.12. Оператор не предоставляет другим Сторонам какую-либо инструкцию по эксплуатации и/или иную документацию в отношении Сервиса и/или Платформы.
      </p>
      <p>
        5.13. Стороны осведомлены и не оспаривают, что Сервис и/или Платформа используется без сертификации, проверки на соответствие ГОСТ, техническим регламентам.
      </p>
      <p>
        5.14. Для Клиента право использования Платформы предоставляется на безвозмездной основе
      </p>

      <p>
        <b>
          6. ОБЯЗАТЕЛЬСТВА СТОРОН
        </b>
      </p>
      <p>
        6.1. Предоставление безопасного и открытого Сервиса и/или Платформы широкому кругу лиц требует, чтобы Клиент соблюдал Соглашение.
      </p>
      <p>
        6.2. Обеспечение безопасности ПДН достигается, в частности:
      </p>
      <p>
        6.2. Клиент не имеет права выдавать себя за других физических лиц или указывать неточную информацию. Клиент предоставляет свои персональные данные для их внесения в Анкету. В случае, если Клиент передаёт свои персональные данные какой-либо из Сторон (за исключением Оператора) для их внесения в Анкету, такая Сторона обязуется удостовериться в достоверности персональных данных Клиента, а также в том, что Клиент находится в здравом уме и действует по своей доброй воле.
      </p>
      <p>
        6.3. Клиент обязан раскрывать свою личность в Сервисе и/или Платформе, Клиент должен предоставить Сервису и/или Платформе точную и актуальную информацию. Кроме того, Клиент не имеет права выдавать себя за того, кем Клиент не является. Ни одна из Сторон не вправе заполнять Анкету для других лиц, кроме Клиента, без их прямого разрешения.
      </p>
      <p>
        6.4. Клиент, Автопроизводитель, Дилер, Поставщик услуг, Сайт не имеют права совершать незаконные, вводящие в заблуждение или обманные действия либо иные действия в незаконных или несанкционированных целях.
      </p>
      <p>
        6.5. Клиент, Автопроизводитель, Дилер, Поставщик услуг, Сайт не имеют права предпринимать попытки сбора информации или доступа к ней несанкционированными способами.
      </p>

      <p>
        <b>
          7. ОБРАБОТКА АНКЕТЫ
        </b>
      </p>
      <p>
        7.1. Оператор вправе по своему усмотрению не обрабатывать данные, предоставленные в Анкете, и не передавать Анкету Автопроизводителю, Дилеру, Поставщику услуг или Сайту. Оператор не несет ответственности за потери данных (части данных) при передаче данных Анкеты или ее части Автопроизводителю, Дилеру, Поставщику услуг, Сайту.
      </p>
      <p>
        7.2. Клиент соглашается и осознает, что информационно-телекоммуникационная сеть «Интернет» не является безопасным каналом связи, и согласен нести риски, связанные с возможным нарушением конфиденциальности и целостности информации при ее передаче через информационно-телекоммуникационную сеть «Интернет», а также согласен нести риски, связанные с тем, что направленные сообщения станут доступными третьим лицам.
      </p>
      <p>
        7.3. Заполнение Анкеты, передача ее Поставщику услуг, принятие ее к рассмотрению, а также возможные расходы Клиента и/или поручителя (на оформление необходимых для заключения договора документов, на проведение экспертизы и т.п.) не влечет за собой обязательства Оператора или Поставщика услуг предоставить и/или обеспечить предоставление Клиенту какой-либо услуги (заключить договор) или возместить понесенные Клиентом и/или поручителем издержки.
      </p>

      <p>
        <b>
          8. ПРАВА ПО СОГЛАШЕНИЮ
        </b>
      </p>
      <p>
        8.1. Настоящее Соглашение не дает никаких прав третьим лицам, если дополнительно не предусмотрено иное.
      </p>
      <p>
        8.2. Клиенту запрещается передавать свои права или обязанности по настоящему Соглашению третьим лицам.
      </p>
      <p>
        8.3. Стороны, за исключением Клиента, вправе уступить права и обязанности по Соглашению другим лицам в случае смены собственника (при слиянии, поглощении или продаже активов), при переходе прав и обязанностей по договору или в силу закона.
      </p>
      <p>
        8.4. Оператор вправе на основании полученного согласия на запрос в бюро кредитных историй собирать и обрабатывать информацию о Клиенте на предмет заключения им какого-либо договора, заключенного без помощи Сервиса и/или Платформы.
      </p>

      <p>
        <b>
          9. ОТВЕТСТВЕННОСТЬ
        </b>
      </p>
      <p>
        9.1. Сервис и/или Платформа предоставляется "как есть" и не может гарантировать его безопасность, защиту и идеальную работу. В той мере, в какой это разрешено законом, Оператор также отказывается от всех прямо выраженных и подразумеваемых гарантий, в том числе подразумеваемых гарантий пригодности для целей, в которых обычно используются такие программные обеспечения, или для конкретных целей, чистоты права собственности и отсутствия нарушений прав на интеллектуальную собственность.
      </p>
      <p>
        9.2. Оператор также не контролирует действия Автопроизводителя, Дилера, Поставщика услуг, Сайта и исходит из презумпции добросовестности названных Сторон. Кроме того, Оператор не несет ответственности за функции и услуги, предлагаемые Автопроизводителем, Дилером, Поставщиком услуг, Сайтом и/или другими людьми или компаниями, даже если Клиент осуществляет доступ к ним через Сервис и/или Платформу.
      </p>
      <p>
        9.3. В случае возникновения проблем с Сервисом и/или Платформой, Оператор не в состоянии предсказать все их возможные последствия. Клиент, Автопроизводитель, Дилер, Поставщик услуг, Сайт соглашаются, что Оператор не будет нести ответственность за любую упущенную прибыль или выручку, потерянную информацию или данные либо за косвенные, штрафные или побочные убытки, возникающие из настоящего Соглашения или в связи с ним, даже если Оператору было известно о возможности их возникновения. Это положение распространяется также на удаление Оператором данных Анкеты Клиента и/или иной информации.
      </p>
      <p>
        9.4. Каждая из Сторон, используя Сервис и/или Платформу, обязуется действовать добросовестно и даёт основание другим Сторонам полагаться на действительность сделки.
      </p>

      <p>
        <b>
          10. ГАРАНТИИ В ОТНОШЕНИИ СЕРВИСА И/ИЛИ ПЛАТФОРМЫ
        </b>
      </p>
      <p>
        10.1. Оператор не гарантирует доступность Сервиса и/или Платформы в любое время;
      </p>
      <p>
        10.2. Оператор не несёт ответственности за коммерческую пригодность Сервиса и/или Платформы, не гарантирует, что использование Сервиса и/или Платформы принесёт Клиенту, Автопроизводителю, Дилерсу, Поставщику услуг, Сайту финансовую или иную выгоду.
      </p>
      <p>
        10.3. Оператор не гарантирует, что Сервис и/или Платформа полностью свободен от дефектов, ошибок и будет работать бесперебойно.
      </p>

      <p>
        <b>
          11. РАЗРЕШЕНИЕ СПОРОВ
        </b>
      </p>
      <p>
        11.1. В случае невозможности разрешения разногласий путем переговоров, Сторона Соглашения, считающая, что ее права и интересы нарушены в ходе исполнения настоящего Соглашения (заявившая претензию) вправе такой спор передать на рассмотрение в суд по месту нахождения Оператора.
      </p>

      <p>
        <b>
          12. ОБНОВЛЕНИЕ СОГЛАШЕНИЯ
        </b>
      </p>
      <p>
        12.1. Оператор может в любой момент изменять Соглашение и сам Сервис и/или Платформу.
      </p>
      <p>
        12.2. Оператор имеет право без уведомления Сторон внести изменения в настоящее Соглашение для точного отражения правил использования Сервиса и/или Платформы.
      </p>
      <p>

        12.3. Каждая из Сторон обязуется самостоятельно знакомиться с условиями настоящего Соглашения и отлеживать изменения настоящего Соглашения путем регулярного, не реже 1 (одного) раза в 14 (четырнадцать) календарных дней, ознакомления с содержанием настоящего Соглашения.
      </p>
      <p>
        12.4. Продолжение использования Сервиса и/или Платформы Стороной означает принятие Соглашения и изменений и/или дополнений, внесенных в настоящее Соглашение.
      </p>

      <p>
        <b>
          8. РЕКВИЗИТЫ ОБЩЕСТВА
        </b>
      </p>
      <p>
        Общество с ограниченной ответственностью «Кредитит»
      </p>
      <table>
        <tbody>
        <tr>
          <td><b>ИНН</b></td>
          <td>9710013385</td>
        </tr>
        <tr>
          <td><b>КПП</b></td>
          <td>770201001</td>
        </tr>
        <tr>
          <td><b>ОГРН</b></td>
          <td>1167746577019</td>
        </tr>
        <tr>
          <td><b>Адрес</b></td>
          <td>115035, Россия, г. Москва, вн.тер.г. Муниципальный Округ Замоскворечье, ул Садовническая, д. 82, стр. 2
          </td>
        </tr>
        <tr>
          <td><b>Контактный телефон</b></td>
          <td>+7 (495) 120-13-67</td>
        </tr>
        <tr>
          <td><b>Сайт компании</b></td>
          <td><a href="http://ecredit.one">http://ecredit.one</a></td>
        </tr>
        </tbody>
      </table>
    </div>
  )
}
