import React from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { Typography } from 'components/UI';

import { selectDealer } from 'modules/car';
import { selectFullName, selectPassport, selectRegistrationAddress } from 'modules/form';

import './personal-data-agreement.scss';

export const PersonalDataAgreementMazda = () => {
  const dealerInfo = useSelector(selectDealer);
  const fullName = useSelector(selectFullName).trim() || '_____________________________________';
  const {
    series = '_________',
    number = '_________________',
    date =  '_____________',
    issuerCode = '____________',
    issuerName = '______________________',
  } = useSelector(selectPassport);

  const address = useSelector(selectRegistrationAddress) || '_________________________________________________________________________';

  return (
    <div className="personal-data-agreement personal-data-agreement-mazda">
      <Typography text="Согласие на обработку персональных данных" className="personal-data-agreement-mazda__header" />
      <p>
       {`Я, ФИО ${fullName}, Паспорт: Серия ${series}, № ${number}, дата выдачи ${date}, код подразделения ${issuerCode}, кем выдан ${issuerName}, зарегистрирован(а) по адресу ${address},`}
      </p>
      <p>
        настоящим даю свое согласие,следующим юридическим лицам (далее «Оператор», или при совместном упоминании «Операторы»):
      </p>
      <table>
        <tbody>
          <tr>
            <td width="25%">ООО «Мазда Мотор Рус»</td>
            <td width="20%">ИНН 7743580770</td>
            <td>125171, г. Москва, Ленинградское шоссе, 16А,стр. 2, 9й этаж</td>
          </tr>
          <tr>
            <td>ПАО «Росбанк»</td>
            <td>ИНН 5012003647</td>
            <td>Россия, 443013, Самарская обл., Самара г, Чернореченская ул, д.42а</td>
          </tr>
          <tr>
            <td>ООО Кредитит</td>
            <td>ИНН 9710013385</td>
            <td>Россия, 115035, г. Москва, вн.тер.г. Муниципальный Округ Замоскворечье, ул Садовническая, д. 82, стр. 2</td>
          </tr>
          <tr>
            <td>{dealerInfo.fullName}</td>
            <td>ИНН {dealerInfo.inn}</td>
            <td>{dealerInfo.address}</td>
          </tr>
        </tbody>
      </table>
      <p>
        При этом, я ознакомлен и даю свое согласие на обработку моих персональных данных в соответствии с Политикой (правилами) обработки персональных данных каждого из Операторов в отдельности.
      </p>
      <p>
        Учитывая, что в соответствии с п. 5 ч. 1 ст. 6 Федерального закона от 27.07.2006 №152-ФЗ «О персональных данных» обработка персональных данных допускается в случаях,
        если такая обработка необходима для исполнения договора, даю своё согласие на обработку Операторами любыми способами предусмотренными действующим законодательством РФ,
        в том числе передачу по защищенным информационным каналам информационно-коммуникационной сети «Интернет», а также на совершение любых действий с указанными
        персональными данными с использованием средств автоматизации или без, в том числе на сбор, запись, систематизацию, накопление, хранение, уточнение, извлечение,
        использование, обезличивание, блокирование, передачу третьим лицам, в том числе участникам группы компаний Оператора, осуществляющим операции
        с денежными средствами или иным имуществом (в том числе информации и документов, полученных с целью моей идентификации, обновления информации обо
        мне и установления сведений в соответствии с пп. 1 и 5 п. 1 ст. 7.3 Федерального закона от 07.08.2001 № 115-ФЗ «О противодействии легализации (отмыванию) доходов,
        полученных преступным путем, и финансированию терроризма»), их работникам и иным уполномоченным ими лицам, удаление и уничтожение, моих персональных данных,
        содержащихся в анкете на получение кредита, в том числе, но не ограничиваясь:
      </p>
      <ul className="personal-data-agreement__personal-info">
          <li>фамилия, имя, отчество;</li>
          <li>пол;</li>
          <li>дата рождения;</li>
          <li>данные регистрации по месту жительства;</li>
          <li>адрес фактического проживания;</li>
          <li>электронная почта;</li>
          <li>номер мобильного телефона;</li>
          <li>данные гражданского паспорта;</li>
          <li>данные о семейном положении;</li>
          <li>данные водительского удостоверения;</li>
          <li>данные о месте работы;</li>
          <li>VIN номер автомобиля.</li>
        </ul>
      <p>
        Целями предоставления мною персональных данных является возможность заключения с Оператором договоров по оказанию банковских услуг (кредитного договора, договора рефинансирования, иных договоров), иных гражданско-правовых договоров (в том числе заключения договора лизинга, займа) и их дальнейшее исполнение, страхование моей жизни/здоровья/имущества и иного страхование, осуществляемое при содействии Оператора или в пользу Оператора и/или в связи с заключением сделок между мной и Оператором, направление мне рекламных и/или информационных материалов, с помощью средств связи, в том числе, по сетям электросвязи, посредством использования телефонной, факсимильной, подвижной радиотелефонной связи (включая СМС - сообщения), а также по сети «Интернет» (включая мессенджеры и электронную почту). Настоящее согласие также действует на передачу моих персональных данных третьим лицам, непосредственно оказывающим запрошенную мной услугу – организациям, являющимися партнёрами Операторов.
      </p>
      <p>
        Согласие действует по достижении целей обработки или случая утраты необходимости в достижении этих целей. Настоящее согласие может быть отозвано путем предоставления мной заявления Оператору в простой письменной форме в соответствии с требованиями законодательства Российской Федерации. В случае получения моего письменного заявления об отзыве настоящего согласия на обработку персональных данных, Оператор обязан прекратить их обработку, если иное не установлено действующим законодательством Российской Федерации.
      </p>
      <p>
        Я подтверждаю, что, давая такое согласие на обработку моих персональных данных, действую по собственной воле и в своих интересах.
      </p>
      <div className="personal-data-agreement-mazda__footer">
        <table>
          <tbody>
            <tr>
              <td>ФИО:</td>
              <td>{fullName}</td>
            </tr>
            <tr>
              <td>Дата:</td>
              <td>{format(new Date(), "dd.MM.yyyy")}</td>
            </tr>
            <tr>
              <td>Идентификатор ПЭП:</td>
              <td>_____________________________________</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
