import React from 'react'
import { useSelector } from 'react-redux';

import { Typography } from 'components/UI';

import { selectBirthDate, selectFullName } from 'modules/form';

export const PersonalDataProcessingPolicy = () => {
  const birthDate = useSelector(selectBirthDate) || '_______________';
  const fullName = useSelector(selectFullName).trim() || '_______________';

  return (
    <div>
      <Typography text="Политика обработки Персональных данных" />

      <p>
        <b>
          1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ
        </b>
      </p>

      <table>
        <tbody>
          <tr>
            <td><b>Общество</b></td>
            <td>Общество с ограниченной ответственностью «Кредитит» ИНН 9710013385 ОГРН 1167746577019, использующее Политику;</td>
          </tr>
          <tr>
            <td><b>ПДН</b></td>
            <td>Персональные данные, а именно любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных);</td>
          </tr>
          <tr>
            <td><b>Политика</b></td>
            <td>настоящий документ, определяющий политику обработки ПДН;</td>
          </tr>
          <tr>
            <td><b>Сайт</b></td>
            <td>Сайта Общества в информационно-телекоммуникационной сети «Интернет», расположенный по адресу https://ecredit.one/;</td>
          </tr>
          <tr>
            <td><b>Уничтожение ПДН</b></td>
            <td>Действия Общества, в результате которых невозможно восстановить содержание ПДН в информационной системе ПДН и/или в результате которых уничтожаются материальные носители ПДН;</td>
          </tr>
          <tr>
            <td><b>ФЗ</b></td>
            <td>Федеральный закон Российской Федерации «О персональных данных» от 27.07.2006 N 152-ФЗ в действующей редакции.</td>
          </tr>
        </tbody>
      </table>

      <p>
        <b>
          2. ОБЩИЕ ПОЛОЖЕНИЯ
        </b>
      </p>

      <p>
        2.1. Настоящий документ определяет Политику Общества в отношении обработки ПДН. Настоящая Политика составлена в соответствии с п. 2 ст. 18.1 ФЗ.
      </p>
      <p>
        2.2. Общество зарегистрировано в Реестре операторов, осуществляющих обработку персональных данных под регистрационным номером 77-17-005535 в соответствии с Приказом № 54 от 22.02.2017.
      </p>
      <p>
        2.3. Политика разработана и действует в соответствии с действующим законодательством Российской Федерации о ПДН, нормативными документами исполнительных органов государственной власти по вопросам безопасности ПДН, в том числе при их обработке в информационных системах ПДН, локально-нормативными актами Общества.
      </p>
      <p>
        2.4. Действие Политики распространяется на все процессы по сбору, записи, систематизации, накоплению, хранению, уточнению, извлечению, использованию, передаче (распространению, предоставлению, доступу), обезличиванию, блокированию, удалению, уничтожению ПДН, осуществляемых с использованием средств автоматизации и без использования таких средств.
      </p>
      <p>
        2.5. В состав обрабатываемых ПДН входят: фамилия, имя, отчество; год рождения; месяц рождения; дата рождения; место рождения; адрес; семейное положение; социальное положение; фотографическое изображение, сведения о трудовой деятельности, сведения о воинском учете военнообязанных лиц и лиц, подлежащих призыву на военную службу; имущественное положение; образование; доходы; состояние здоровья; пол; реквизиты документа, удостоверяющего личность (серия и номер документа, дата выдачи, наименование органа, выдавшего документ и код подразделения); водительский стаж; гражданство; номер страхового свидетельства государственного пенсионного страхования; сведения о страховом полисе, сведения о страховых выплатах; сведения о страховых случаях; информация по застрахованному транспортному средству (тип, марка, модель, год выпуска, номерной знак, документ на ТС); идентификационный номер налогоплательщика, контактные данные; файлы cookies; данные о поведении и предпочтениях пользователей.
      </p>
      <p>
        2.6. Политика обязательна для ознакомления и исполнения руководителями структурных подразделений, работники которых принимают участие в обработке ПДН.
      </p>
      <p>
        2.7. Политика подлежит публикации на Сайте с целью обеспечения неограниченного доступа к Политике.
      </p>
      <p>
        2.8. В случае внесения изменений в Политику, актуальная версия Политики незамедлительно публикуется на Сайте.
      </p>

      <p>
        <b>
          3. ПРИНЦИПЫ ОБРАБОТКИ ПДН
        </b>
      </p>

      <p>
        3.1. При обработке ПДН Общество придерживается следующих принципов:
      </p>
      <p>
        3.1.1. законности и справедливой основы;
      </p>
      <p>
        3.1.2. ограничения обработки ПДН достижением конкретных, заранее определенных и законных целей;
      </p>
      <p>
        3.1.3. недопущения объединения баз данных, содержащих ПДН, обработка которых осуществляется в целях, несовместных между собой;
      </p>
      <p>
        3.1.4. соответствия содержания и объема обрабатываемых ПДН заявленным целям обработки;
      </p>
      <p>
        3.1.5. недопущения обработки ПДН, несовместимых или избыточных по отношению к заявленным целям обработки ПДН;
      </p>
      <p>
        3.1.3. недопущения объединения баз данных, содержащих ПДН, обработка которых осуществляется в целях, несовместных между собой;
      </p>
      <p>
        3.1.6. обеспечения точности, достаточности, а в необходимых случаях и актуальности ПДН по отношению к заявленным целям обработки;
      </p>
      <p>
        3.1.7. уничтожения либо обезличивания ПДН по достижении целей их обработки или в случае утраты необходимости в достижении этих целей, если срок хранения ПДН не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем, по которому является субъект ПДН.
      </p>

      <p>
        <b>
          4. УСЛОВИЯ ОБРАБОТКИ ПДН
        </b>
      </p>
      <p>
        4.1. Обработка ПДН в Обществе допускается только при наличии одного из следующих условий:
      </p>
      <p>
        4.1.1. обработка ПДН осуществляется с согласия субъекта ПДН на обработку его ПДН;
      </p>
      <p>
        4.1.2. обработка ПДН необходима для достижения целей, предусмотренных международным договором Российской Федерации или законом, для осуществления и выполнения возложенных законодательством Российской Федерации на оператора функций, полномочий и обязанностей;
      </p>
      <p>
        4.1.3. обработка ПДН необходима для осуществления правосудия, исполнения судебного акта, акта другого органа или должностного лица, подлежащих исполнению в соответствии с законодательством Российской Федерации об исполнительном производстве;
      </p>
      <p>
        4.1.4. обработка ПДН необходима для предоставления государственной или муниципальной услуги в соответствии с федеральным законом от 27 июля 2010 года №210-ФЗ «Об организации предоставления государственных и муниципальных услуг», для обеспечения предоставления такой услуги, для регистрации субъекта ПДН на едином портале государственных и муниципальных услуг;
      </p>
      <p>
        4.1.5. обработка ПДН необходима для исполнения договора, стороной которого либо выгодоприобретателем или поручителем, по которому является субъект ПДН, а также для заключения договора по инициативе субъекта ПДН или договора, по которому субъект ПДН будет являться выгодоприобретателем или поручителем;
      </p>
      <p>
        4.1.6. обработка ПДН необходима для защиты жизни, здоровья или иных жизненно важных интересов субъекта ПДН, если получение согласия субъекта ПДН невозможно;
      </p>
      <p>
        4.1.7. обработка ПДН необходима для осуществления прав и законных интересов оператора или третьих лиц, либо для достижения общественно значимых целей при условии, что при этом не нарушаются права и свободы субъекта ПДН;
      </p>
      <p>
        4.1.8. обработка ПДН осуществляется в статистических или иных исследовательских целях, при условии обязательного обезличивания ПДН. Исключение составляет обработка ПДН в целях продвижения товаров, работ, услуг на рынке путем осуществления прямых контактов с потенциальным потребителем с помощью средств связи, а также в целях политической агитации;
      </p>
      <p>
        4.1.9. осуществляется обработка ПДН, доступ неограниченного круга лиц к которым предоставлен субъектом ПДН, либо по его просьбе (далее – ПДН, сделанные общедоступными субъектом ПДН);
      </p>
      <p>
        4.1.10. осуществляется обработка ПДН, подлежащих опубликованию или обязательному раскрытию в соответствии с действующим законодательством РФ.
      </p>
      <p>
        4.2. Для обработки ПДН необходимо наличие письменного согласия субъекта ПДН в следующих случаях (за исключением специально обговоренных в ФЗ):
      </p>
      <p>
        4.2.1. включение ПДН субъекта в общедоступные источники ПДН;
      </p>
      <p>
        4.2.2. обработка специальных категорий ПДН, касающихся расовой, национальной принадлежности, политических взглядов, религиозных или философских убеждений, состояния здоровья, интимной жизни;
      </p>
      <p>
        4.2.3. обработка биометрических ПДН (сведений, которые характеризуют физиологические и биологические особенности человека, на основании которых можно установить его личность и которые используются оператором для установления личности субъекта ПДН);
      </p>
      <p>
        4.2.4. трансграничная передача ПДН на территории иностранных государств, не обеспечивающих адекватной защиты прав субъектов ПДН;
      </p>
      <p>
        4.2.5. принятие на основании исключительно автоматизированной обработки ПДН решений, порождающих юридические последствия в отношении субъекта ПДН или иным образом затрагивающих его права и законные интересы.
      </p>
      <p>
        4.3. Равнозначным содержащему собственноручную подпись субъекта ПДН согласию в письменной форме на бумажном носителе признается согласие в форме электронного документа, подписанного в соответствии с федеральным законом электронной подписью (в том числе, но не ограничиваясь, простой электронной подписью).
      </p>
      <p>
        4.4. Общество вправе поручить обработку ПДН другому лицу с согласия субъекта ПДН, если иное не предусмотрено федеральным законом, на основании заключаемого с этим лицом договора. Лицо, осуществляющее обработку ПДН по поручению Общества, обязано соблюдать принципы и правила обработки ПДН, предусмотренные ФЗ.
      </p>
      <p>
        4.5. В случае, если Общество поручает обработку ПДН другому лицу, ответственность перед субъектом ПДН за действия указанного лица несет Общество. Лицо, осуществляющее обработку ПДН по поручению Общества, несет ответственность перед Обществом.
      </p>
      <p>
        4.6. Общество и иные лица, получившие доступ к ПДН, обязаны не раскрывать третьим лицам и не распространять ПДН без согласия субъекта ПДН, если иное не предусмотрено ФЗ.
      </p>

      <p>
        <b>
          5. ПРАВА СУБЪЕКТОВ ПДН
        </b>
      </p>
      <p>
        5.1. Субъект ПДН вправе требовать от Общества уточнения своих ПДН, их блокирования или уничтожения в случаях, если ПДН являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав.
      </p>
      <p>
        5.2. Субъект ПДН имеет право на получение информации, касающейся обработки его ПДН, в том числе содержащей:
      </p>
      <p>
        5.2.1. подтверждение факта обработки ПДН Обществом;
      </p>
      <p>
        5.2.2. правовые основания и цели обработки ПДН;
      </p>
      <p>
        5.2.3. цели и применяемые Обществом способы обработки ПДН;
      </p>
      <p>
        5.2.4. наименование и место нахождения Общества, сведения о лицах (за исключением работников Общества), которые имеют доступ к ПДН или которым могут быть раскрыты ПДН на основании договора с Обществом или на основании ФЗ;
      </p>
      <p>
        5.2.5. обрабатываемые ПДН, относящиеся к соответствующему субъекту ПДН, источник их получения, если иной порядок представления таких данных не предусмотрен ФЗ;
      </p>
      <p>
        5.2.6. сроки обработки ПДН, в том числе сроки их хранения;
      </p>
      <p>
        5.2.7. порядок осуществления субъектом ПДН прав, предусмотренных ФЗ;
      </p>
      <p>
        5.2.8. информацию о ранее осуществленной или о предполагаемой трансграничной передаче данных;
      </p>
      <p>
        5.2.9. наименование или фамилию, имя, отчество и адрес лица, осуществляющего обработку ПДН по поручению Общества, если обработка поручена или будет поручена такому лицу;
      </p>
      <p>
        5.2.10. иные сведения, предусмотренные ФЗ или иным действующим законодательством Российской Федерации.
      </p>
      <p>
        5.3. Для получения информации в соответствии с пунктом 5.2. субъект ПДН может отправить письменный запрос по адресу местонахождения Общества, указанному в Едином государственном реестре юридических лиц (расположенном, в том числе, в информационно-телекоммуникационной сети «Интернет» по адресу https://egrul.nalog.ru/index.html) в порядке, установленном ст. 14, 20, 21 ФЗ. Запрос может быть также направлен в форме электронного документа, подписан электронной подписью и направлен по адресу электронной почты: info@ecredit.one.
      </p>

      <p>
        <b>
          6. МЕРЫ ПО ОБЕСПЕЧЕНИЮ БЕЗОПАСНОСТИ ПДН ПРИ ИХ ОБРАБОТКЕ
        </b>
      </p>
      <p>
        6.1. При обработке ПДН Общество принимает необходимые правовые, организационные и технические меры для защиты ПДН от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения ПДН, а также от иных неправомерных действий в отношении ПДН.
      </p>
      <p>
        6.2. Обеспечение безопасности ПДН достигается, в частности:
      </p>
      <p>
        6.2.1. определением угроз безопасности ПДН при их обработке в информационных системах ПДН;
      </p>
      <p>
        6.2.2. применением организационных и технических мер по обеспечению безопасности ПДН при их обработке в информационных системах ПДН, необходимых для выполнения требований к защите ПДН, исполнение которых обеспечивает установленные Правительством Российской Федерации уровни защищенности ПДН;
      </p>
      <p>
        6.2.3. применением прошедших в установленном порядке процедуру оценки соответствия средств защиты информации;
      </p>
      <p>
        6.2.4. оценкой эффективности принимаемых мер по обеспечению безопасности ПДН до ввода в эксплуатацию информационной системы ПДН;
      </p>
      <p>
        6.2.5. учетом машинных носителей ПДН;
      </p>
      <p>
        6.2.6. обнаружением фактов несанкционированного доступа к ПДН и принятием мер;
      </p>
      <p>
        6.2.7. восстановлением ПДН, модифицированных или уничтоженных вследствие несанкционированного доступа к ним;
      </p>
      <p>
        6.2.8. установлением правил доступа к ПДН, обрабатываемым в информационной системе ПДН, а также обеспечением регистрации и учета всех действий, совершаемых с ПДН в информационной системе ПДН;
      </p>
      <p>
        6.2.9. контролем за принимаемыми мерами по обеспечению безопасности ПДН и уровня защищенности информационных систем ПДН.
      </p>

      <p>
        <b>
          7. УСЛОВИЯ ПРЕКРАЩЕНИЯ ОБРАБОТКИ ПДН
        </b>
      </p>
      <p>
        7.1. Общество прекращает обработку персональных данных в следующих случаях:
      </p>
      <p>
        7.1.1. при наступлении условий прекращения обработки ПДН или по истечении установленных сроков;
      </p>
      <p>
        7.1.2. по достижении целей их обработки либо в случае утраты необходимости в достижении этих целей;
      </p>
      <p>
        7.1.3. по требованию субъекта ПДН, если обрабатываемые в Обществе ПДН являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки;
      </p>
      <p>
        7.1.4. в случае выявления неправомерной обработки ПДН, если обеспечить правомерность обработки ПДН невозможно;
      </p>
      <p>
        7.1.5. в случае отзыва субъектом ПДН согласия на обработку его ПДН или истечения срока действия такого согласия (если ПДН обрабатываются Обществом исключительно на основании согласия субъекта ПДН);
      </p>
      <p>
        7.1.6. в случае ликвидации Общества.
      </p>
      <p>
        7.2. При наступлении случаев, указанных в пункте 7.1. Политики, происходит Уничтожение ПДН.
      </p>

      <p>
        <b>
          8. РЕКВИЗИТЫ ОБЩЕСТВА
        </b>
      </p>
      <p>
        Общество с ограниченной ответственностью «Кредитит»
      </p>
      <table>
        <tbody>
        <tr>
          <td><b>ИНН</b></td>
          <td>9710013385</td>
        </tr>
        <tr>
          <td><b>КПП</b></td>
          <td>770201001</td>
        </tr>
        <tr>
          <td><b>ОГРН</b></td>
          <td>1167746577019</td>
        </tr>
        <tr>
          <td><b>Адрес</b></td>
          <td>115035, Россия, г. Москва, вн.тер.г. Муниципальный Округ Замоскворечье, ул Садовническая, д. 82, стр. 2</td>
        </tr>
        <tr>
          <td><b>Контактный телефон</b></td>
          <td>+7 (495) 120-13-67 </td>
        </tr>
        <tr>
          <td><b>Сайт компании</b></td>
          <td><a href="http://ecredit.one">http://ecredit.one</a></td>
        </tr>
        </tbody>
      </table>
    </div>
  );
}
